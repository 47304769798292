export function setBliderBox(){
  
  let targets = document.getElementsByClassName("js_bliderBox");
  
  for(let i = 0; i < targets.length; i++){
    
    targets[i].addEventListener("click",() => {
      targets[i].classList.toggle('js_isActive');
      //TODO : ボタン内部の文字列を聞き帰る処理を追加する
      //TODO : クローズ時に要素の先頭にスクロールさせる処理を追加する
    }, true);
    
  }
  
}