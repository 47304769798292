//export const body = document.querySelector('.body');
//export const header = document.querySelector('.header');

export function ua(){
  
  const ua = window.navigator.userAgent;
 
  if(ua.indexOf('Edge') != -1 || ua.indexOf('Edg') != -1) {
    console.log('Microsoft Edge');
  } else if(ua.indexOf('Trident') != -1 || ua.indexOf('MSIE') != -1) {
    console.log('Microsoft Internet Explorer');
  } else if(ua.indexOf('OPR') != -1 || ua.indexOf('Opera') != -1) {
    console.log('Opera');
  } else if(ua.indexOf('Chrome') != -1) {
    console.log('Google Chrome');
  } else if(ua.indexOf('Firefox') != -1) {
    console.log('FireFox');
  } else if(ua.indexOf('Safari') != -1) {
    console.log('Safari');
  } else {
    console.log('Unknown');
  }
}
