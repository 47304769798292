//import Vue from 'vue';
import * as func from './functions/_function';
import * as animation from './functions/_animation';
import * as bliderBox from './functions/_bliderBox';
import * as layout from './functions/_layout';
import * as offcanvas from './functions/_offcanvas';
import * as pagetop from './functions/_pagetop';
import * as smoothScroll from './functions/_smoothScroll';
import * as headerFixed from './functions/_headerFixed';
import * as loading from './functions/_loading';
import Modal from '../../node_modules/bootstrap/js/dist/modal'
import * as ua from './functions/_ua';


// グローバル関数
// ------------------------------------------------------------

let CURRENT_SCROLL_POS = 0;
let SCROLLDOWN_FLAG = true;

let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.pageXOffset;
let CURRENT_SC_OFFSET_Y       = window.pageYOffset;
let BLAKEPOINT_HEADER_SP_MODE = 1024;
let DEBUG_MODE = true;

//ua.ua();  
animation.scrollObserver();
loading.initLoading(DEBUG_MODE);                                                        // ローディングアニメーション
headerFixed.setHeaderFixed();                                                 // ヘッダー固定
smoothScroll.setSmoothScroll();                                               // スムーススクロール
offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'app');                      // オフキャンバス


// 全体処理
// ------------------------------------------------------------

window.addEventListener('scroll', () => {
  
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;
  
  // スクロールを催す要素のフェードアウト処理
  // ------------------------------------------------------------
  
  if(document.getElementById('js_scrollDown')){

    let scrollDownElm = document.getElementById('js_scrollDown');
    let interactiveBnrElm = document.getElementById('js_interactive_bnr01');
    
    let mvH = document.getElementById('js_fvSlider').getBoundingClientRect().bottom;
    
    if(window.scrollY > (mvH /2)){
      scrollDownElm.classList.add('js_anime_fadeOut');
      setTimeout(function(){
        scrollDownElm.style.display = "none";
      }, 1000);
    }
  }
  
})

window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
})

// トップページ用のスライドショー関数
// ============================================================

$(function (){
  
  if($('.is_home').length){    //クラスの有無でトップページかどうかを判定
    
    $('#js_fvSlider').slick({  //slickを初期化
      fade:true,               //切り替えをフェードで行う。初期値はfalse。
      autoplay: true,          //自動的に動き出すか。初期値はfalse。
      autoplaySpeed: 1000,     //次のスライドに切り替わる待ち時間
      speed:2000,              //スライドの動きのスピード。初期値は300。
      infinite: true,          //スライドをループさせるかどうか。初期値はtrue。
      slidesToShow: 1,         //スライドを画面に3枚見せる
      slidesToScroll: 1,       //1回のスクロールで3枚の写真を移動して見せる
      arrows: false,           //左右の矢印
      dots: false,             //下部ドットナビゲーションの表示
      pauseOnFocus: false,     //フォーカスで一時停止を無効
      pauseOnHover: false,     //マウスホバーで一時停止を無効
      pauseOnDotsHover: false, //ドットナビゲーションをマウスホバーで一時停止を無効
      adaptiveHeight:true
   });
   
  }
  
})
// 下層ページ用のスライドショー関数
// ============================================================

$(function(){

  if($('.is_page').length){
    
    $('#js_pageSlider').slick({
      fade: true,
      autoplay: true,
      autoplaySpeed: 1000,
      speed: 3000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
    });

    $('#js_pageSliderTab').slick({
      fade: true,
      autoplay: true,
      autoplaySpeed: 1000,
      speed: 3000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
    });    

  }

})

// TODO : 後に外部モジュール化
// ============================================================


// ローディング完了後にアニメーションを発火させる関数

function loadingCallbackAnimation(delay,cb){
  
  // ローディング完了フラグを監視
  let watchLoadedFlagClass = setInterval(function () {
    countUp();
  }, 1);
  
  const countUp = () =>{
    if($('body').hasClass('js_isLoaded')){
      
      setTimeout(function () {
        
        //引数で渡された関数を実行
        cb();
      }, delay);
      clearInterval(watchLoadedFlagClass);
      
    }
  }
}

// ローディング時にコールバックさせるアニメーション関数をセット/制御

function setLoadingCbAnimation() {
  
  //ターゲットクラスの有無を確認
  if($('.js_loading_cb_animation').length){
    
    let targetElms = $('.js_loading_cb_animation');
  
    targetElms.each(function(i){
      let delay = $(this).data('animation-delay');
      $(this).delay(i * delay).queue(function(next){
        $(this).addClass('js_isActive animate__animated');
        next();
      });
    })
  }
}

loadingCallbackAnimation(600,setLoadingCbAnimation);


